var Scheduler = function (selector) {
    var config = {
        'programs': '.schedule_program'
    };
    var currentDateTime = new Date();

    $(selector).find(config.programs).each(function () {
        var programTimeBegin = new Date($(this).data('time-begin'));
        var programTimeEnd = new Date($(this).data('time-end'));

        if (programTimeEnd < currentDateTime) {
            $(this).addClass('is-passed');

            return true;
        } 
        
        if (programTimeBegin < currentDateTime) {
            $(this).addClass('is-active');
            $('.scheduler__scroll').scrollTop($(this).offset().top - 400);

            return false;
        }
    });
};

(function () {
    var schedule = $('section.grille');

    if (schedule.length) {
        Scheduler(schedule);
    }
})();

(function() {
    $( ".earlier" ).click(function() {
        if ($(this).hasClass("show")) {
            $( "li.is-passed" ).each(function( ) {
                this.style.display = "flex";
            });
        } else {
            $( "li.is-passed" ).each(function( ) {
                this.style.display = "none";
            });
        }
        $(this).text($(this).text() == 'En ondes' ? 'Plus tôt aujourd’hui' : 'En ondes');
        $(this).toggleClass("show");
    });
})();