(function() {
    function showSignupMsg(msgEl) {
        msgEl.show();
        setTimeout(function() {
            msgEl.hide();
        }, 3000);
    }

    $('form#subscribe').submit(function (evt){
        evt.preventDefault();

        var form = $(this);
        var data = form.serialize();

        $.ajax({
            url: '/',
            method: 'POST',
            data: data,
            dataType: 'json'
        }).
        done(function(data) {
            if (data.success) {
                showSignupMsg($('.success-signup'));

                form.find('[name="email"]')[0].value = '';
            } else {
                showSignupMsg($('.failure-signup'));
            };
        });
    });
})();