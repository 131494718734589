(function() {
    $( "#showRules" ).click(function() {
        if ($(this).hasClass("show")) {
            $( ".reglement" ).each(function( ) {
                this.style.display = "block";
            });
        } else {
            $( ".reglement" ).each(function( ) {
                this.style.display = "none";
                $("body,html").animate({
                    scrollTop: $("form").offset().top - 100
                }, 800 //speed
                );
            });
        }
        $(this).text($(this).text() == 'Règlement' ? 'Cacher le règlement' : 'Règlement');
        $(this).toggleClass("show");
    });
    $( "#closeRules").click(function() {
        $( ".reglement" ).each(function( ) {
            this.style.display = "none";
            $("body,html").animate({
                scrollTop: $("form").offset().top - 100
            }, 800 //speed
            );
        });
        $("#showRules").text($("#showRules").text() == 'Règlement' ? 'Cacher le règlement' : 'Règlement');
        $("#showRules").toggleClass("show");
    });
})();