// Declaration of vroots namespace
window.vroots = window.vroots || {};

// Display debug informations
window.vroots.verbose = false;

// Require groots JS-Plugins
window.vroots.require = {
    grInlineSVG:            'auto',
    grObjectfitEmulator:    'auto',
    grFormSelect:           false, //todo
    grFormUpload:           false,
    grFormValidation:       'auto' //todo
};

// Let the magic happens
//require('../../../node_modules/slick-carousel/slick/slick.min.js');

require('./components/navbar.js');
require('./components/slider.js');
require('./components/schedule.js');
require('./components/newsletter.js');
require('./components/contest.js');

(function() {
    "use strict";
}(window.groots));
