$(document).ready(function(){
    $('.grille-slider').flickity({
        groupCells: true,
        wrapAround: false,
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        initialIndex: '.is-initial-select'
    });
});

$(document).ready(function(){
    $('.home-slider').flickity({
        groupCells: true,
        wrapAround: false,
        cellAlign: 'center',
        contain: true,
        pageDots: true,
        imagesLoaded: true
    });
});

$(document).ready(function(){
    $('.hero-slider').flickity({
        groupCells: false,
        wrapAround: false,
        cellAlign: 'center',
        contain: true,
        pageDots: true,
        imagesLoaded: true
    });
});